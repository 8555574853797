import { CSidebarClose } from '@coreui/react'
import React, { useState, FC, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive';

interface ThePanelProps {
    closeAsideBar?: any,
    title?: any,
    titleClassName?: string,
    toggle?: boolean,
    setToggle?: any,
    titlePrepend?: any
}

const ThePanelView: FC<ThePanelProps> = ({ children, closeAsideBar, title, titleClassName, toggle = false, setToggle, titlePrepend }) => {
    const [width, setWidth] = useState<string>('32vw')
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
    const isSmall = useMediaQuery({ query: '(max-width: 1000px)' })

    useEffect(() => {
        if (isMobile) {
            setWidth('mobile')
        }
        else {
            if (isSmall) {
                setWidth('small')
            }
            else {
                setWidth('regular')
            }
        }
    }, [isMobile, isSmall])


    return (
        <>
            {
                toggle &&
                <div className='Panel-Container-View'>
                    <div dir={localStorage.getItem('i18nextLng') === 'en' ? 'ltr' : 'rtl'} className={`Panel ${width}`}>
                        <div className='Panel-Header'>
                            {/* <CSidebarClose onClick={closeAsideBar} /> */}
                            <button className={'closeButton'} onClick={closeAsideBar}></button>
                            <div className={titleClassName}>
                                {titlePrepend}
                                {title}
                            </div>
                        </div>
                        {children}
                    </div>
                </div>
            }
        </>

    )
}

export default ThePanelView