import { Scale } from '@antv/scale'
import React ,{FC, useEffect}from 'react'
interface Props {
//  disabled:boolean
    checked:any
    title?:string
    action:any,
    checkedColor?:string,
    checkboxContent?:string|undefined,
    titleClass?: string
}

const Checkbox:FC<Props>=({checkboxContent,checkedColor,title,action,checked,titleClass})=>{
    useEffect(()=>{
    
    },[checked])
    return(
        <div className='Checkbox-Container'>
            <div onClick={action} className='Checkbox-View' >
                {(checked|| checkboxContent) &&<div className={'Checkbox-checked'} style={{backgroundColor:checkedColor?checkedColor:'default'}}>
                    <span className={'checkbox-content'}>{checkboxContent}</span>
                </div>}
            </div>
             <div className={`Checkbox-Title ${titleClass || ''}`}>{title}</div>
        </div>
    )
}

 export default Checkbox