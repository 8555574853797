export const LOGIN = {
  USER_AUTHENTICATION_SUCCESS: 209,
  USER_AUTHENTICATION_FAILURE: -209,
  TOKEN_GENERATION_SUCCESS: 210,
  TOKEN_GENERATION_FAILURE: -210,
};

export const CHANGE_FARM = {
  LIST_USER_FARMS_SUCCESS: 211,
  FARMS_AND_PLOTS_CALCULATION_SUCCESS: 212,
  LIST_USER_FARMS_FAILURE: -211,
  LIST_USER_FARMS_EMPTY: -212,
  LIST_FARM_CROPS: 200,
};

export const VALVE_CONTROL = {
  FIND_MACHINE_ID_SUCCESSFULLY: 104,
  UPDATED_MACHINE_STATUS_SUCCESSFULLY: 105,
  CERVELLO_CONNECTION_SUCCESS: 106,
  FIND_MACHINE_ID_FAILURE: -104,
  UPDATED_MACHINE_STATUS_FAILURE: -105,
  CERVELLO_CONNECTION_FAILURE: -106,
};

export const UPDATE_PROGRAM = {
  MISSING_HEADER: -300,
  PROGRAM_UPDATE_SUCCESS: 213,
  FAILED_DUE_TO_FERTIGATION_OCCURRENCE: -214,
  FAILED_DUE_TO_SQL_FAILURE: -215,
  FAILED_DUE_TO_ILLEGAL_ARGUMENT: -216,
  PROGRAM_UPDATE_FAILURE: -213,
};

export const ADD_PROGRAM = {
  ADD_PROGRAM_SUCCESS: 220,
  ADD_PROGRAM_FAILURE: -220,
  FAILED_DUE_TO_FERTIGATION_OCCURRENCE: -214,
  FAILED_DUE_TO_SQL_FAILURE: -215,
  FAILED_DUE_TO_ILLEGAL_ARGUMENT: -216,
  FAILED_DUE_TO_DUPLICATION: -217,
};

export const PROGRAM_ACTIVATION = {
  EVENT_CONFLICT_OCCURED: -221,
  PROGRAM_ACTIVATION_SUCCESS: 222,
  PROGRAM_DEACTIVATION_SUCCESS: 223,
  PROGRAM_ACTIVATION_OR_DEACTIVATION_FAILURE: -222,
  PROGRAM_HAS_ACTIVE_EVENTS: -223,
  PROGRAM_DELETE_OLD_EVENTS_FAIL: -226,
  PROGRAM_ALREADY_ACTIVE: -227,
  PROGRAM_ALREADY_INACTIVE: -228,
};

export const NEW_IRRIGATION_OR_FERTIGATION_EVENT = {
  EVENTS_CREATED_SUCCESSFULLY: 200,
  FAILED_TO_CREATE_EVENTS: 0,
  EVENTS_CONFLICTS: -100,
};

export const NEW_REMINDER_EVENT = {
  REMINDER_CREATED_SUCCESSFULLY: 224,
  FAILED_TO_CREATE_REMINDER: -224,
};

export const DELETE_EVENT = {
  EVENT_DELETED_SUCCESSFULLY: 225,
  FAILED_TO_DELETE_EVENT: -225,
};

export const FORECAST_ALERTS = {
  FORECAST_ALERTS_RECEIVED_SUCCESSFULLY: 206,
  FORECAST_ALERTS_RETRIEVAL_FAILURE: -206,
};

export const ADD_MANUAL_METER = {
  SENSOR_METER_ADDED_SUCCESSFULLY: 250,
  DUPLICATE_SENSOR_METER_NAME: -250,
  DUPLICATE_SENSOR_NAME: -253,
  LOCATION_DUPLICATION: -254,
};

export const VIEW_MANUAL_METERS = {
  MANUAL_METERS_RETRIEVED_SUCCESSFULLY: 257,
};

export const UPDATE_MANUAL_METER = {
  METER_UPDATED_SUCCESSFULLY: 253,
};

export const DELETE_MANUAL_METER = {
  METER_DELETED_SUCCESSFULLY: 252,
};
export const DELETE_MANUAL_SENSOR = {
  SENSOR_DELETED_SUCCESSFULLY: 255,
};

export const MANAGEMENT_ROLE = {
  EMAIL_CONFLICT_ERROR: -2,
  PHONE_CONFLICT_ERROR: -1,
  USER_EXISTS_IN_ORG: -223,
  ADD_USER_FAILED: -214,
  ADD_USER_SUCCESS: 214,
  MAX_USERS_EXCEEDED: 402,
  GET_USER_DETAILS_SUCCESS: 200,
  UPDATE_MEMBER_SUCCESS: 220,
  FETCH_FARM_ROLES_SUCCESS: 219,
  USER_SUSPENDED_SUCCESSFULLY: 221,
  READD_USER_SUCCESS: 222,
  USER_ACTIVATE_SUCCESS: 373,
  USER_ACTIVATE_FAIL: -373,
  TEMP_USER_DELETED_SUCCESS: 374,
  TEMP_USER_DELETED_FAIL: -374,
  TEMP_USER_DELETED_FAIL_ALREADY_ACTIVATED: -375,
  TEMP_USER_DELETED_FAIL_DOES_NOT_EXIST: -376,
};
export const ASSIGN_TO = {
  REPORT_ASSIGNING_SUCCESS: 602,
  REPORT_ASSIGNING_FAILED: -602,
};
export const DUPLICATE = {
  REPORT_DUPLICATION_SUCCESS: 605,
  REPORT_DUPLICATION_FAILED: -605,
};
export const RENAME = {
  RENAME_SUCCESS: 607,
  RENAME_FAILED: -607,
};
export const ORGANIZATIONS = {
  GET_ORGANIZATION_SUCCESS: 370,
  GET_ORGANIZATION_FAILED: -370,
  GET_ORGANIZATION_DETAILS_SUCCESS: 801,
};
export const SUBSCRIBTION = {
  SUBSCRIPTION_SUCCESS: 801,
  SUBSCRIPTION_FAILED: 402,
};
export const DEFINE_FARM = {
  DEFINE_FARM_SUCESS: 201,
  ORGANIZATION_CAPACITY_EXCEEDED: 402,
  DUPLICATE_FARM_EXIST: 9,
};
export const REPORTS_LIST_DROPDOWN = {
  LIST_SUCCESS: 601,
  LIST_FAILED: -601,
};
export const MACHINERY = {
  DELETE_SUCCESS: 202,
  DELETE_FAILED: 404,
  UPDATE_SUCCESS: 202,
  UPDATE_FAILED: 400,
  ADD_SUCCESS: 201,
};
export const EMPLOYEE = {
  DELETE_SUCCESS: 904,
  DELETE_FAILED: -904,
  UPDATE_SUCCESS: 903,
  UPDATE_FAILED: -903,
  ADD_FAILED: -902,
  ADD_SUCCESS: 902,
  EMPLOYMENTTYPE_SUCCESS: 901,
};

export const DELETE_FARM = {
  FARM_DELETE_SUCCESS: 313,
  FARM_DELETE_FAIL: -313,
};

export const CHANGE_EVENT_STATUS = {
  CHANGE_STATUS_SUCCESS: 202,
  CHANGE_STATUS_FAILURE: 500,
};
export const FUNDS = {
  ADD_FUND_REQUEST_SUCCESS: 1000,
  ADD_FUND_REQUEST_FAILED: -1000,
  GET_FUND_REQUEST_SUCCESS: 1001,
  GET_FUND_REQUEST_FAILED: -1001,
  GET_FUND_REQUEST_EMPTY: -1002,
  ADD_FUND_REQUEST_DUPLICATED: 9
};
