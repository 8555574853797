import React, {useState,FC, useEffect} from 'react'

interface Props{
  show:boolean|any
  closeModal:any
  title?:any
}
const Modal:FC<Props>=(props) => {
  const [show,setShow]=useState(props.show)  
  
  useEffect(()=>{
    setShow(props.show)
  },[props.show])
  return(
    show?
      <div className={'modalBackground'}>
        <div className={'modalContainer'}>
          <div style={{justifyContent:props.title?'space-between':'flex-end'}} className={'closeButtonContainer'}>
            {props.title}
            <button className={'closeButton'} onClick={props.closeModal}></button>
          </div>
          {props.children}
        </div>
      </div>:<></>
    );
}

export default Modal;