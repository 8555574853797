export const FARMS_COMPONENTS = {
    WEATHER: 1,
    IRRIGATION: 2,
    SOIL: 3,
    WATER: 4,
    CROP: 5,
    NONE: 6,
    SATELLITE: 7
}

export const SENSORS_CATEGORY = {
    MANUAL: 1,
    PHYSICAL: 2,
    FORECAST: 3,
    SATELLITE: 4
}
export const VEGETATION_INDICES = {
    FARM_VIEW: 0,
    NDVI: 1,
    NDRE: 2,
    RECI: 3,
    MSAVI: 4,
    ELEVATION: 5,
    NDMI: 6
}

export const SMALL_SCREEN_WIDTH = 1024

export const USER_STATUS = {
    ACTIVE: 1,
    SUSPENDED: 2,
    PENDING: 3,
}

export const MAP_SEARCH_MODES: any = {
    LOCATION: 0,
    COORDINATES: 1,
    WGS: 2,
    LINK: 3
}

